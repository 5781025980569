import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../pages/HomePage.vue';
import ResultDHPage from '../pages/ResultDHPage.vue';
import SeedingDHPage from '../pages/SeedingDHPage.vue';
import ResultBMXPage from '../pages/ResultBMXPage.vue';

const routes = [
    {
        path: '',
        component: HomePage,
      },
  {
    path: '/dh/:raceId',
    component: ResultDHPage,
    props: true, // Passes route params as props to the component
  },
  {
    path: '/result-dh/:step/:class/:raceId',
    component: SeedingDHPage,
    props: true, // Passes route params as props to the component
  },
  {
    path: '/bmx/:class/:raceId',
    component: ResultBMXPage,
    props: true, // Passes route params as props to the component
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
