<template>
    <div class="container">
        <div class="logo">
            <img src="@/assets/logo-jtm.png" alt="Logo">
        </div>
        <div class="text">
            <h1>We’ll be back soon!</h1>
            <p>Untuk melihat hasil race. Gunakan URL yang diberikan oleh panitia</p>
            <p>&mdash; The JTM Reborn Team</p>
        </div>
    </div>
</template>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    background-color: #f5f5f5;
    /* Background color (optional) */
}

.logo img {
    max-width: 150px;
    /* Adjust size as needed, in pixels */
    height: auto;
    /* Maintain aspect ratio */
    margin-bottom: 20px;
    /* Space between logo and text */
}

.text h1 {
    font-size: 2em;
    margin-bottom: 10px;
    /* Space between heading and paragraph */
}

.text p {
    font-size: 1em;
    margin-bottom: 10px;
    /* Space between paragraphs */
}

a {
    color: #007bff;
    /* Link color (optional) */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    /* Underline on hover (optional) */
}
</style>