<template>
  <div class="race-results-overlay ">
    <div class="race-results">
      <h1>Race Results</h1>
      <div class="controls">
        <div>
          <label for="step">Step:</label>
          <select v-model="selectedStep" id="step">
            <option value="">Select Step</option>
            <option value="Seeding Run">Seeding Run</option>
            <option value="Final Run">Final Run</option>
          </select>
        </div>
        <div>
          <label for="class">Class:</label>
          <select v-model="selectedClass" id="class">
            <option value="">Select Class</option>
            <option v-for="raceClass in raceClasses" :key="raceClass" :value="raceClass">
              {{ raceClass }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="!selectedStep || !selectedClass" class="no-selection">
        Please select both a step and a class to view results.
      </div>

      <ul v-else class="results-list">
        <li v-for="(participant, index) in participants" :key="participant.id" :class="{
          'gold': index === 0,
          'silver': index === 1,
          'bronze': index === 2 || index === 3,
          'umum': index > 3,
        }">
          <span class="position">{{ index + 1 }}</span>
          <span class="name">{{ participant.name }}</span>
          <span class="team">{{ participant.team }}</span>
          <span class="time" v-if="selectedStep === 'Seeding Run'">
            {{ formatTime(participant.seedingTime) }}
          </span>
          <span class="time" v-else-if="selectedStep === 'Final Run'">
            {{ formatTime(participant.finalTime) }}
          </span>
        </li>
        <li v-for="(participant, index) in unFinishParticipants" :key="participant.id" :class="{
          'unfinish': index >= 0,
        }">
          <span class="position">{{ index + 1 }}</span>
          <span class="name">{{ participant.name }}</span>
          <span class="team">{{ participant.team }}</span>
          <span class="time" v-if="selectedStep === 'Seeding Run'">
            {{ participant.statusSeeding }}
          </span>
          <span class="time" v-else-if="selectedStep === 'Final Run'">
            {{ participant.statusFinal }}
          </span>
        </li>

      </ul>
    </div>
  </div>
</template>



<script>
import { ref, onMounted, watch } from 'vue';
import { collection, doc, getDoc, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as necessary

export default {
  props: {
    raceId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const selectedClass = ref('');
    const selectedStep = ref('');
    const raceClasses = ref([]);
    const participants = ref([]);
    const unFinishParticipants = ref([]);
    const newlyAdded = ref([]);

    const fetchRaceClasses = async () => {
      try {
        const docRef = doc(db, 'races', props.raceId);
        const docSnapshot = await getDoc(docRef);
        console.log('Fetching race classes for:', props.raceId); // Debug

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          console.log('Document data:', data);
          raceClasses.value = data.raceClasses || [];
          console.log('Fetched race classes:', raceClasses.value); // Debug
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching race classes:', error);
      }
    };
    const convertToMilliseconds = (time) => {
      if (typeof time !== 'string' || !time.trim()) return Infinity; // Handle non-string or empty values

      // Example time format: "00:10.324"
      const [minutes, rest] = time.split(':');
      const [seconds, milliseconds] = rest.split('.');

      // Default to 0 if parsing fails
      const parsedMinutes = parseInt(minutes, 10) || 0;
      const parsedSeconds = parseInt(seconds, 10) || 0;
      const parsedMilliseconds = parseInt(milliseconds, 10) || 0;

      return (parsedMinutes * 60000) + (parsedSeconds * 1000) + parsedMilliseconds;
    };

    const getFilteredParticipants = () => {
      let q = query(collection(db, 'races', props.raceId, 'participants'));

      // Apply filter by class if selected
      if (selectedClass.value) {
        q = query(q, where('class', '==', selectedClass.value));
      }

      // Apply sorting based on selected step
      if (selectedStep.value === 'Seeding Run') {
        q = query(q, where('seedingTime', '!=', null), orderBy('seedingTime', 'asc'));
      } else if (selectedStep.value === 'Final Run') {
        q = query(q, where('finalTime', '!=', null), orderBy('finalTime', 'asc'));
      } else {
        q = query(q, where('finalTime', '!=', null), orderBy('finalTime', 'asc'));
      }

      // Set up a real-time listener
      onSnapshot(q, (querySnapshot) => {
        const participantsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Sort participants based on selected step
        if (selectedStep.value === 'Seeding Run') {
          participantsData.sort((a, b) => {
            const aTime = convertToMilliseconds(a.seedingTime);
            const bTime = convertToMilliseconds(b.seedingTime);
            return aTime - bTime;
          });
        } else if (selectedStep.value === 'Final Run') {
          participantsData.sort((a, b) => {
            const aTime = convertToMilliseconds(a.finalTime);
            const bTime = convertToMilliseconds(b.finalTime);
            return aTime - bTime;
          });
        } else {
          participantsData.sort((a, b) => {
            const aTime = convertToMilliseconds(a.finalTime);
            const bTime = convertToMilliseconds(b.finalTime);
            return aTime - bTime;
          });
        }

        const newParticipantIds = participantsData.map(p => p.id);
        newlyAdded.value = newParticipantIds;

        participants.value = participantsData;

        setTimeout(() => {
          newlyAdded.value = [];
        }, 5000); // Remove highlight after 4 seconds
        console.log('Updated participants data:', newlyAdded); // Debug
      });
    };
    const getUnFinishParticipants = () => {
    console.log('getUnFinishParticipants function is running');

    let q = query(collection(db, 'races', props.raceId, 'participants'));

    if (selectedClass.value) {
        q = query(q, where('class', '==', selectedClass.value));
        console.log('Applied class filter:', selectedClass.value);
    }

    try {
        if (selectedStep.value === 'Seeding Run') {
            q = query(q, where('statusSeeding', '==', 'DNS'));
            console.log('Applied Seeding Run filter');
        } else if (selectedStep.value === 'Final Run') {
            q = query(q, where('statusFinal', 'in', ['DNS', 'DNF']));
            console.log('Applied Final Run filter');
        } else {
            q = query(q, where('statusFinal', 'in', ['DNS', 'DNF']));
            console.log('Applied default Final Run filter');
        }

        onSnapshot(q, (querySnapshot) => {
            console.log('onSnapshot triggered');
            const participantsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            unFinishParticipants.value = participantsData;
            console.log('Unfinished participants:', unFinishParticipants.value);
        }, (error) => {
            console.error('Error with snapshot listener:', error);
        });
    } catch (error) {
        console.error('Error setting up snapshot listener:', error);
    }
};



    onMounted(() => {
      fetchRaceClasses();
      getFilteredParticipants();
      getUnFinishParticipants();
    });

    watch([selectedClass, selectedStep], () => {
      // Reapply filters when selectedClass or selectedStep changes
      getFilteredParticipants();
      getUnFinishParticipants();
    });

    const formatTime = (time) => {
      if (!time) return 'N/A';
      const minutes = Math.floor(time / 60000);
      const seconds = Math.floor((time % 60000) / 1000);
      const milliseconds = time % 1000;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`;
    };

    return {
      selectedClass,
      selectedStep,
      raceClasses,
      participants,
      unFinishParticipants,
      formatTime,
      newlyAdded,
    };
  },
};

</script>

<style scoped>
.no-selection {
  color: #ffcccc;
  background-color: #3a506b;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
}

.race-results-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
}

.race-results {
  width: 90%;
  max-width: 500px;
  background-color: #1c3d5a;
  color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.controls {
  margin-bottom: 20px;
}

.controls label {
  display: block;
  margin-bottom: 5px;
}

.controls select {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  background-color: #3a506b;
  color: #ffffff;
}

.results-list {
  list-style-type: none;
  padding: 0;
}

.results-list li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ffffff;
}

.results-list li.gold {
  background-color: gold;
  color: #1c3d5a;
  font-weight: bold;
}

.results-list li.silver {
  background-color: silver;
  color: #1c3d5a;
  font-weight: bold;
}

.results-list li.bronze {
  background-color: #cd7f32;
  color: #1c3d5a;
  font-weight: bold;
}

.results-list li.umum {
  background-color: white;
  color: #1c3d5a;
  font-weight: bold;
}

.results-list li.unfinish {
  background-color: red;
  color: #1c3d5a;
  font-weight: bold;
}


.results-list li.new-highlight {
  background-color: orange;
  font-weight: bold;
}

.position {
  font-size: 1.2em;
  margin-right: 10px;
}

.name {
  flex: 2;
}

.team {
  flex: 2;
}

.time {
  flex: 1;
  text-align: right;
}
</style>