// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {

    apiKey: "AIzaSyCMoDlsNbzh1x4bThUB5nERUohli_DP1U4",

    authDomain: "jtm-timing.firebaseapp.com",

    projectId: "jtm-timing",

    storageBucket: "jtm-timing.appspot.com",

    messagingSenderId: "565829539816",

    appId: "1:565829539816:web:a448e6125cbc0930f6b65d",

    measurementId: "G-KRF073WSKZ"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
