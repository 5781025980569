<template>
    <div class="race-results-overlay">
        <div class="race-results">
            <h3>BMX/Dirt Jump</h3>
            <h2>PORKAB Cirebon 2024</h2>
            <h4>Kelas {{ selectedClass }}</h4>

            <div v-if="loadingParticipants" class="loading">
                <p>Loading participants...</p>
            </div>
            <div v-else-if="participants.length === 0">
                <p>No race results available</p>
            </div>
            <div v-else>
                <div class="results-grid">

                    <div class="results-header">
                        <div class="position-header">Position</div>
                        <div class="name-header">Name</div>
                        <div class="team-header">Team</div>
                        <div class="time-header">Run 1</div>
                        <div class="time-header">Run 2</div>
                    </div>

                    <div v-for="(participant, index) in participants" :key="participant.id" :class="{
                        'gold': index === 0,
                        'silver': index === 1,
                        'bronze': index === 2 || index === 3,
                    }" class="results-row" style=" display: gird;">
                        <div class="position">{{ index + 1 }}</div>
                        <div class="name">{{ participant.name }}</div>
                        <div class="team">{{ participant.team }}</div>
                        <div :class="{ bold: participant.bestTime === participant.run1_time }" class="time">
                            {{ formatTime(participant.run1_time) }}
                        </div>
                        <div :class="{ bold: participant.bestTime === participant.run2_time }" class="time">
                            {{ formatTime(participant.run2_time) }}
                        </div>
                    </div>
                </div>
            </div>
            <footer class="footer">
                <p>
                    <span class="powered-by">Powered by </span>
                    <span class="jtm-timing-system">JTM Timing System</span>
                    <img src="@/assets/logo-jtm.png" alt="JTM Logo" class="footer-logo" />
                </p>
            </footer>
        </div>
    </div>
</template>



<script>
import { ref, watch, onMounted } from 'vue';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as necessary

export default {
    props: {
        raceId: {
            type: String,
            required: true,
        },
        class: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const run = ref(2); // Number of runs
        const participants = ref([]);
        const loadingParticipants = ref(false);
        const selectedClass = ref(props.class);

        // Apply conditional value assignments
        if (selectedClass.value) {
            if (selectedClass.value === 'porkab') {
                selectedClass.value = 'PORKAB';
            } else if (selectedClass.value === 'a') {
                selectedClass.value = 'HOBI A';
            } else if (selectedClass.value === 'b') {
                selectedClass.value = 'HOBI B';
            }
        }

        const fetchParticipants = async () => {
            loadingParticipants.value = true;
            console.log('Fetching participants for raceId:', props.raceId, 'and class:', selectedClass.value);

            try {
                let q = query(collection(db, 'races', props.raceId, 'participants'));

                // Apply filter by class if selected
                if (selectedClass.value) {
                    q = query(q, where('class', '==', selectedClass.value));
                }

                // Real-time updates with onSnapshot
                onSnapshot(q, (querySnapshot) => {
                    let fetchedParticipants = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    fetchedParticipants = fetchedParticipants.filter(
    (participant) => typeof participant.run1_time !== 'undefined' && participant.run1_time !== null
);


                    fetchedParticipants.sort((a, b) => {
                        const aMinTime = getMinimumTime(a);
                        const bMinTime = getMinimumTime(b);
                        return aMinTime - bMinTime;
                    });

                    participants.value = fetchedParticipants.map((participant) => ({
                        ...participant,
                        bestTime: getMinimumTime(participant),
                    }));

                    console.log('Participants updated successfully:', participants.value);
                });
            } catch (error) {
                console.error('Error fetching participants:', error.message);
            } finally {
                loadingParticipants.value = false;
            }
        };

        const getMinimumTime = (data) => {
            const times = [];

            if (data.run1_time !== null && data.run1_time !== undefined) {
                times.push(data.run1_time);
            }

            if (data.run2_time !== null && data.run2_time !== undefined) {
                times.push(data.run2_time);
            }

            // If only one run time is available, use it as the best time
            if (times.length === 0) {
                return 999999999; // or any large value if no times are available
            }

            return Math.min(...times);
        };


        const formatTime = (finalTime) => {
            if (finalTime === undefined || finalTime === null) {
                return '00:00.000'; // Default value when time is not available
            }
            const minutes = Math.floor(finalTime / 60000);
            const seconds = Math.floor((finalTime % 60000) / 1000);
            const milliseconds = finalTime % 1000;
            return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
                2,
                '0'
            )}.${String(milliseconds).padStart(3, '0')}`;
        };

        onMounted(fetchParticipants);

        watch(() => props.class, fetchParticipants); // Watch for changes in the class prop

        return {
            run,
            participants,
            loadingParticipants,
            selectedClass,
            formatTime,
        };
    },
};
</script>

<style scoped>
.loading {
    text-align: center;
    margin: 20px 0;
}

.race-results-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 00);
    /* Adjust for overlay transparency */
    z-index: 1000;
}

.race-results {
    width: 90%;
    max-width: 800px;
    min-height: 300px;
    background-color: #1c3d5a;
    color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.results-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* gap: 10px; */

}

/* .results-header .position-header,

.results-header .name-header,

.results-header .team-header,

.results-header .time-header {

    background-color: #cd7f32;

} */

.results-header,
.results-row {
    display: contents;
}

.umum .position,

.umum .name,

.umum .team {

    background-color: white;
    color: #1c3d5a;
    font-weight: bold;

}

.umum .time {

    background-color: white;
    color: #1c3d5a;

}

.gold .position,

.gold .name,

.gold .team {

    background-color: gold;
    color: #1c3d5a;
    font-weight: bold;

}

.gold .time {
    background-color: gold;
    color: #1c3d5a;
}

.silver .position,

.silver .name,

.silver .team {

    background-color: silver;
    color: #1c3d5a;
    font-weight: bold;

}

.silver .time {

    background-color: silver;
    color: #1c3d5a;

}

.bronze .position,

.bronze .name,

.bronze .team {
    background-color: #cd7f32;
    color: #1c3d5a;
    font-weight: bold;

}

.bronze .time {
    background-color: #cd7f32;
    color: #1c3d5a;
    font-weight: bold;

}


.results-header {
    font-weight: bold;
    border-bottom: 2px solid #ffffff;
    background-color: #2e5d9a;
}

.position-header,
.name-header,
.team-header,
.time-header {
    padding: 10px;
}

.results-row {
    border-bottom: 1px solid #ffffff;
}

.position,
.name,
.team,
.time {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.bold {
    font-weight: bold;
}

.position {
    font-size: 1.2em;
}

.name {
    flex: 2;
}

.team {
    flex: 2;
}

.time {
    flex: 1;
    text-align: right;
}

.footer {
    color: #ffffff;
    font-size: 0.9em;
    text-align: center;
    padding: 10px;
    background-color: #1c3d5a;
    /* Match footer background to .race-results */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* Space between text and logo */
    margin-top: 50px;
}

.footer p {
    display: flex;
    align-items: center;
    margin: 0;
    /* Remove default margins */
}

.footer-logo {
    margin-left: 20px;
    height: 50px;
    /* Adjust as needed */
    width: auto;
    /* Maintain aspect ratio */
}

.footer .powered-by {
    color: #ffffff;
    /* White color for "Powered By" */
}

.footer .jtm-timing-system {
    color: #FFA500;
    /* Orange color for "JTM Timing System" */
    margin-left: 6px;
    font-weight: bold;
}
</style>
