<template>
  <div class="race-results-overlay ">
    <div class="race-results">
      <h1>Hasil Race</h1>
      <h1>PORKAB Cirebon 2024</h1>
      <h2>Kelas {{ selectedClass }}</h2>

      <ul class="results-list">
        <li>
          <span class="position" style="font-weight: bold;">No</span>
          <span class="name" style="font-weight: bold;">Nama</span>
          <span class="team" style="font-weight: bold;">Tim</span>
          <span class="time" style="font-weight: bold;">Waktu</span>
        </li>
        <li v-for="(participant, index) in participants" :key="participant.id" :class="{
          'gold': index === 0,
          'silver': index === 1,
          'bronze': index === 2 || index === 3,
          'umum': index >3
        }">
          <span class="position">{{ index + 1 }}</span>
          <span class="name">{{ participant.name }}</span>
          <span class="team">{{ participant.team }}</span>
          <span class="time" v-if="selectedStep === 'seeding'">
            {{ formatTime(participant.seedingTime) }}
          </span>
          <span class="time" v-else-if="selectedStep === 'final'">
            {{ formatTime(participant.finalTime) }}
          </span>
        </li>
      </ul>
      <footer class="footer">
        <p>
          <span class="powered-by">Powered by </span> 
          <span class="jtm-timing-system">JTM Timing System</span>
          <img src="@/assets/logo-jtm.png" alt="JTM Logo" class="footer-logo" />
        </p>
      </footer>
    </div>
    
  </div>
</template>



<script>
import { ref, onMounted, watch } from 'vue';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as necessary

export default {
  props: {
    raceId: {
      type: String,
      required: true,
    },
    step: {
      type: String,
      required: true,
    },
    class: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const selectedClass = ref(props.class);
    const selectedStep = ref(props.step);
    // const raceClasses = ref([]);
    const participants = ref([]);
    const newlyAdded = ref([]);

    // const fetchRaceClasses = async () => {
    //   try {
    //     const docRef = doc(db, 'races', props.raceId);
    //     const docSnapshot = await getDoc(docRef);
    //     console.log('Fetching race classes for:', props.raceId); // Debug

    //     if (docSnapshot.exists()) {
    //       const data = docSnapshot.data();
    //       console.log('Document data:', data);
    //       raceClasses.value = data.raceClasses || [];
    //       console.log('Fetched race classes:', raceClasses.value); // Debug
    //     } else {
    //       console.log('No such document!');
    //     }
    //   } catch (error) {
    //     console.error('Error fetching race classes:', error);
    //   }
    // };
    const convertToMilliseconds = (time) => {
      if (typeof time !== 'string' || !time.trim()) return Infinity; // Handle non-string or empty values

      // Example time format: "00:10.324"
      const [minutes, rest] = time.split(':');
      const [seconds, milliseconds] = rest.split('.');

      // Default to 0 if parsing fails
      const parsedMinutes = parseInt(minutes, 10) || 0;
      const parsedSeconds = parseInt(seconds, 10) || 0;
      const parsedMilliseconds = parseInt(milliseconds, 10) || 0;

      return (parsedMinutes * 60000) + (parsedSeconds * 1000) + parsedMilliseconds;
    };

    const getFilteredParticipants = () => {
      let q = query(collection(db, 'races', props.raceId, 'participants'));

      // Apply filter by class if selected
      if (selectedClass.value) {
        if (selectedClass.value === 'porkab') {
          selectedClass.value = 'PORKAB'
        } else if (selectedClass.value === 'a') {
          selectedClass.value = 'HOBI A'
        } else if (selectedClass.value === 'b') {
          selectedClass.value = 'HOBI B'
        }
        q = query(q, where('class', '==', selectedClass.value));
      }

      // Apply sorting based on selected step
      if (selectedStep.value === 'seeding') {
        q = query(q, where('seedingTime', '!=', null), orderBy('seedingTime', 'asc'));
      } else if (selectedStep.value === 'final') {
        q = query(q, where('finalTime', '!=', null), orderBy('finalTime', 'asc'));
      } else {
        q = query(q, where('finalTime', '!=', null), orderBy('finalTime', 'asc'));
      }

      // Set up a real-time listener
      onSnapshot(q, (querySnapshot) => {
        const participantsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Sort participants based on selected step
        if (selectedStep.value === 'Seeding Run') {
          participantsData.sort((a, b) => {
            const aTime = convertToMilliseconds(a.seedingTime);
            const bTime = convertToMilliseconds(b.seedingTime);
            return aTime - bTime;
          });
        } else if (selectedStep.value === 'Final Run') {
          participantsData.sort((a, b) => {
            const aTime = convertToMilliseconds(a.finalTime);
            const bTime = convertToMilliseconds(b.finalTime);
            return aTime - bTime;
          });
        } else {
          participantsData.sort((a, b) => {
            const aTime = convertToMilliseconds(a.finalTime);
            const bTime = convertToMilliseconds(b.finalTime);
            return aTime - bTime;
          });
        }


        participants.value = participantsData;


        console.log('Updated participants data:', newlyAdded); // Debug
      });
    };

    onMounted(() => {
      getFilteredParticipants();
    });

    watch([selectedClass, selectedStep], () => {
      // Reapply filters when selectedClass or selectedStep changes
      getFilteredParticipants();
    });

    const formatTime = (time) => {
      if (!time) return 'N/A';
      const minutes = Math.floor(time / 60000);
      const seconds = Math.floor((time % 60000) / 1000);
      const milliseconds = time % 1000;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds.toString().padStart(3, '0')}`;
    };

    return {
      selectedClass,
      selectedStep,

      participants,
      formatTime,
    };
  },
};

</script>

<style scoped>
.no-selection {
  color: #ffcccc;
  background-color: #3a506b;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
  font-size: 1.2em;
}

.race-results-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
}

.race-results {
  width: 90%;
  max-width: 500px;
  min-height: 300px;
  background-color: #1c3d5a;
  color: #ffffff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.controls {
  margin-bottom: 20px;
}

.controls label {
  display: block;
  margin-bottom: 5px;
}

.controls select {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  background-color: #3a506b;
  color: #ffffff;
}

.results-list {
  list-style-type: none;
  padding: 0;
}

.results-list li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  /* border-bottom: 1px solid #ffffff; */
  border-radius: 8px; 
  margin-bottom: 5px;
}

.results-list li.gold {
  background-color: gold;
  color: #1c3d5a;
  font-weight: bold;
}

.results-list li.silver {
  background-color: silver;
  color: #1c3d5a;
  font-weight: bold;
}

.results-list li.bronze {
  background-color: #cd7f32;
  color: #1c3d5a;
  font-weight: bold;
}

.results-list li.umum {
  background-color: #ffffff;
  color: #1c3d5a;
  font-weight: bold;
}

.results-list li.new-highlight {
  background-color: orange;
  font-weight: bold;
}

.position {
  font-size: 1.2em;
  margin-right: 10px;
}

.name {
  flex: 2;
}

.team {
  flex: 2;
}

.time {
  flex: 1;
  text-align: right;
}
.footer {
  color: #ffffff;
  font-size: 0.9em;
  text-align: center;
  padding: 10px;
  background-color: #1c3d5a; /* Match footer background to .race-results */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between text and logo */
  margin-top: 50px;
}

.footer p {
  display: flex;
  align-items: center;
  margin: 0; /* Remove default margins */
}

.footer-logo {
  margin-left: 20px;
  height: 50px; /* Adjust as needed */
  width: auto; /* Maintain aspect ratio */
}
.footer .powered-by {
  color: #ffffff; /* White color for "Powered By" */
}

.footer .jtm-timing-system {
  color: #FFA500; /* Orange color for "JTM Timing System" */
  margin-left: 6px;
  font-weight: bold;
}

</style>